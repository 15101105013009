import React from "react";
import { Box, Flex, Heading, Link, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import circlesBg from "assets/header-bkg.svg";

interface DashboardMenu {
  title: string;
  description: string;
  url: string;
  buttonLabel: string;
  icon: string;
  isExternal?: boolean;
}

export const DashboardMenu = ({
  title,
  description,
  url,
  buttonLabel,
  icon,
  isExternal,
}: DashboardMenu) => (
  <VStack
    boxShadow="md"
    borderTopStartRadius="2xl"
    borderTopEndRadius="2xl"
    borderEndEndRadius="2xl"
    borderEndStartRadius="2xl"
    bgColor="white"
    w="300px"
    minW="300px"
    h="350px"
  >
    <Flex
      bgColor="cherryUi.600"
      w="100%"
      borderTopStartRadius="2xl"
      borderTopEndRadius="2xl"
      position="relative"
      height="100px"
    >
      <Image
        src={icon}
        w="80px"
        h="80px"
        alt="Cherryhub icon"
        borderRadius="full"
        shadow="lg"
        position="absolute"
        top="10px"
        left={5}
        zIndex="2"
      />
      <Box
        bgColor="cherryUi.800"
        bgImage={circlesBg}
        bgRepeat="no-repeat"
        bgSize="cover"
        w="100%"
        h="60px"
        position="absolute"
        bottom="0px"
        zIndex="0"
      />
    </Flex>
    <VStack pt={2} px={5} pb={8} alignItems="flex-start" flexGrow={1}>
      <Heading as="h3" size="lg" color="cherryUi.600">
        cherry
        <Text as="span" fontWeight="100">
          {title}
        </Text>
      </Heading>
      <Text pb={4} fontSize="sm" flexGrow={1}>
        {description}
      </Text>
      <Button
        as={Link}
        href={url}
        isExternal={isExternal ?? false}
        variant="outline"
        colorScheme="cherryButton"
        borderRadius="3xl"
      >
        {buttonLabel}
      </Button>
    </VStack>
  </VStack>
);
