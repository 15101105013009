import React, { useCallback, useState } from "react";
import {
  fetchSignInMethodsForEmail,
  User,
  linkWithCredential,
  unlink,
  OAuthProvider,
  OAuthCredential,
  AuthCredential,
} from "firebase/auth";
import { useFirebase } from "../../context/FirebaseContext";
import { Box, VStack, Text } from "@chakra-ui/layout";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import cherryLogo from "assets/cherryhub_large.png";
import loginBg from "assets/login-bkg.svg";
import { LoginForm } from "../../forms/LoginForm";
import { useToast } from "../../hooks/useToast";
import { PasswordResetForm } from "../../forms/PasswordResetForm";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

const PASSWORD_METHOD = "password";

interface LoginProps {
  onLoginSuccess?: (businessId?: string) => void;
}

const decodeJwt = (accessToken: string) => {
  try {
    return JSON.parse(window.atob(accessToken.split(".")[1]));
  } catch (e) {
    return {};
  }
};

const AuthFormContainer = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <VStack
      width="100%"
      bg="#fff"
      borderRadius="8"
      shadow="md"
      maxWidth="400px"
      margin="0 auto"
      overflow="hidden"
    >
      <Text
        width="100%"
        textAlign="center"
        color="#fff"
        backgroundColor="cherryUi.600"
        fontWeight="300"
        padding="4"
      >
        {title}
      </Text>
      <VStack width="100%" padding="4">
        {children}
      </VStack>
    </VStack>
  );
};

export const Login = ({ onLoginSuccess }: LoginProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useFirebase();
  const { showErrorToast, showSuccessToast } = useToast();

  const [authForm, setAuthForm] = useState<"login" | "reset">("login");
  const [email, setEmail] = useState<string>();
  const [credential, setCredential] = useState<OAuthCredential>();

  const onClickResetPassword = useCallback(
    () => setAuthForm("reset"),
    [setAuthForm]
  );

  const onCancelResetPassword = useCallback(
    () => setAuthForm("login"),
    [setAuthForm]
  );

  const onPasswordResetSuccess = useCallback(() => {
    showSuccessToast("Password reset email has beem sent.");
    setAuthForm("login");
  }, [setAuthForm, showSuccessToast]);

  const onSuccess = useCallback(
    (user: User) => {
      // Access the users decoded JWT so that we can read the businessId field.
      const decodedJwt = decodeJwt((user as any).accessToken);

      if (onLoginSuccess) {
        onLoginSuccess(decodedJwt.businessId);
      }
    },
    [onLoginSuccess]
  );

  const onMicrosoftCatch = async (error: any) => {
    const providers = await fetchSignInMethodsForEmail(
      auth,
      error.customData.email
    );
    if (providers.indexOf(PASSWORD_METHOD) != -1) {
      const authCredential = await OAuthProvider.credentialFromError(error);
      if (authCredential) {
        setCredential(authCredential);
      }
      setEmail(error.customData.email);
      onOpen();
    } else {
      showErrorToast(
        "Your account is linked to a provider that isn't supported."
      );
    }
  };

  const onMicrosoftCatchSuccess = useCallback(
    async (user: User) => {
      if (credential) {
        await linkWithCredential(user, credential as AuthCredential);
        setCredential(undefined);
        setEmail(undefined);
        const currUser = await unlink(user, PASSWORD_METHOD);
        // Access the users decoded JWT so that we can read the businessId field.
        const decodedJwt = decodeJwt((currUser as any).accessToken);

        if (onLoginSuccess) {
          onLoginSuccess(decodedJwt.businessId);
        }
      }
    },
    [onLoginSuccess, credential, linkWithCredential]
  );

  return (
    <>
      <Image src={loginBg} position="absolute" minWidth="1200px" top="0" />
      <Box position="relative">
        <VStack height="100vh" position="relative" spacing="8" paddingTop="8">
          <Box
            boxSize="140px"
            borderRadius="full"
            bg="#fff"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={cherryLogo} alt="Cherryhub Logo" boxSize="110px" />
          </Box>
          {authForm === "login" && (
            <AuthFormContainer title="Sign in to your account">
              <LoginForm
                firebaseAuth={auth}
                onError={showErrorToast}
                onSuccess={onSuccess}
                onMicrosoftCatch={onMicrosoftCatch}
              />
              <Button
                variant="ghost"
                fontWeight="400"
                onClick={onClickResetPassword}
              >
                I don't know my password
              </Button>
            </AuthFormContainer>
          )}
          {authForm === "reset" && (
            <AuthFormContainer title="Reset your password">
              <PasswordResetForm
                firebaseAuth={auth}
                onError={showErrorToast}
                onCancel={onCancelResetPassword}
                onSuccess={onPasswordResetSuccess}
              />
            </AuthFormContainer>
          )}
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link with Microsoft Account</ModalHeader>
          <ModalBody textAlign="center" pb={0}>
            <VStack w="100%" alignItems="flex-start">
              <ErrorMessage textAlign="start">
                An existing Cherry Hub account was found. <br />
                <br />
                Please sign in now using your existing account to merge it with
                your Microsoft account. <br />
                <br />
                If you proceed, you must log in using your Microsoft account in
                the future.
              </ErrorMessage>
              <LoginForm
                email={email}
                firebaseAuth={auth}
                onError={showErrorToast}
                onSuccess={onMicrosoftCatchSuccess}
              />
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center" pb={6}>
            <Button colorScheme="blue" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
