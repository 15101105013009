import React from "react";
import { FormikErrors, FormikProps, withFormik } from "formik";
import { Divider, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { isEmptyStr } from "../util/StringUtil";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import {
  Auth,
  signInWithEmailAndPassword,
  User,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { MicrosoftIcon } from "../styles/icons";

const ERROR_CODE_EXISTS_DIFF_CRED =
  "auth/account-exists-with-different-credential";

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  email?: string;
  onSuccess?: (user: User) => void;
  onError?: (message: string) => void;
  onMicrosoftCatch?: (error: any) => void;
  firebaseAuth: Auth;
}

const InnerForm = ({
  firebaseAuth,
  onSuccess,
  onError,
  onMicrosoftCatch,
  isSubmitting,
  isValid,
  setErrors,
}: LoginFormProps & FormikProps<LoginFormValues>) => {
  const microsoftProvider = new OAuthProvider("microsoft.com");

  const signInWithMicrosoft = (): Promise<FirebaseLoginResult> => {
    return new Promise((resolve, reject) => {
      signInWithPopup(firebaseAuth, microsoftProvider)
        .then((result) => {
          resolve({ ok: true, user: result.user });
        })
        .catch((error, ...rest) => {
          console.log(error.code);
          console.log(error.message);
          if (error.code == ERROR_CODE_EXISTS_DIFF_CRED && onMicrosoftCatch) {
            onMicrosoftCatch(error);
          } else {
            resolve({
              ok: false,
              errorCode: error.code,
              errorMessage: error.message,
            });
          }
        });
    });
  };

  return (
    <>
      <FormStack>
        <TextField name="email" label="Email" placeholder="Email Address" />
        <TextField
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
        />

        <VStack width="100%" justifyContent="center" spacing="3" pt="4">
          <Button
            isLoading={isSubmitting}
            colorScheme="cherryButton"
            color="#fff"
            type="submit"
            disabled={isSubmitting || !isValid}
            paddingLeft="4"
            paddingRight="4"
            width="100px"
          >
            Next
          </Button>

          {onMicrosoftCatch && (
            <>
              <Divider />

              <Button
                isLoading={isSubmitting}
                colorScheme="microsoftBlue"
                color="#fff"
                disabled={isSubmitting}
                paddingLeft="4"
                paddingRight="4"
                onClick={signInWithMicrosoft}
                leftIcon={<MicrosoftIcon />}
              >
                Sign in with Microsoft
              </Button>
            </>
          )}
        </VStack>
      </FormStack>
    </>
  );
};
type FirebaseLoginResult =
  | { ok: true; user: User }
  | { ok: false; errorCode: string; errorMessage: string };
type T = { [key: string]: number };

// Maps firebase error codes to login form validation messages.
const firebaseErrorCodeMap: {
  [key: string]: Partial<{ [P in keyof LoginFormValues]: string }>;
} = {
  "auth/invalid-email": { email: "Invalid email address." },
  "auth/user-not-found": { email: "User not found." },
  "auth/user-disabled": { email: "This account has been disabled." },
  "auth/wrong-password": { password: "Invalid password" },
  "auth/too-many-requests": { password: "Too many attempts. Try again later." },
};

const firebaseLogin = (
  auth: Auth,
  email: string,
  password: string
): Promise<FirebaseLoginResult> => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        resolve({ ok: true, user: result.user });
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
        resolve({
          ok: false,
          errorCode: error.code,
          errorMessage: error.message,
        });
      });
  });
};

const handleLogin = (
  loginResult: FirebaseLoginResult,
  setErrors: (errors: FormikErrors<LoginFormValues>) => void,
  onSuccess?: (user: User) => void,
  onError?: (message: string) => void
) => {
  if (!loginResult.ok) {
    // Map firebase error codes to validation messages.
    const formErrors = firebaseErrorCodeMap[loginResult.errorCode];

    if (formErrors) {
      setErrors(formErrors);
    } else {
      console.error(`Firebase login error: ${loginResult.errorMessage}`);
      if (onError) {
        onError("Login error.");
      }
    }
  } else {
    if (onSuccess) {
      onSuccess(loginResult.user);
    }
  }
};

export const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  mapPropsToValues: (props) => {
    return {
      email: props.email ?? "",
      password: "",
    };
  },

  handleSubmit: async (values, { props, setFieldError, setErrors }) => {
    let isInvalid = false;
    if (isEmptyStr(values.email)) {
      setFieldError("email", "Email is required");
      isInvalid = true;
      return;
    }

    if (isEmptyStr(values.password)) {
      setFieldError("password", "Password is required");
      isInvalid = true;
      return;
    }

    const loginResult = await firebaseLogin(
      props.firebaseAuth,
      values.email,
      values.password
    );

    handleLogin(loginResult, setErrors, props.onSuccess, props.onError);
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: (values) => {},
})(InnerForm);
