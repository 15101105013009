import React from "react";
import { Box, Flex, Heading, Link, Text, VStack } from "@chakra-ui/layout";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { HStack, IconButton } from "@chakra-ui/react";
import { QuestionIcon } from "../../styles/icons";
import { useUserContext } from "../../context/UserContext";
import hubIcon from "assets/cherryhub_logo.svg";
import { useBusinessContext } from "../../context/ModelContext";
import { CherryPayApi } from "../../api/models";
import {
  LicencedProduct,
  LicencedProductList,
} from "../../enums/LicencedProduct";
import { DashboardMenu } from "../../components/DashboardMenu/DashboardMenu";

export const BusinessDashboard = () => {
  const { LicencedCherryHubProducts: products, BusinessId } =
    useBusinessContext();
  const { user } = useUserContext();

  const findoutMoreProducts =
    LicencedProductList()?.filter(
      (product) =>
        products?.indexOf(product) == -1 &&
        product != LicencedProduct.CherryPlay
    ) || [];

  const WrapIconButton = ({
    label,
    url,
    icon,
  }: {
    label: string;
    url: string;
    icon: React.ReactElement;
  }) => (
    <Box position="relative" w="fit-content">
      <IconButton
        as={Link}
        href={url}
        aria-label="Settings"
        title="Settings"
        icon={icon}
        size="xl"
        colorScheme="cherryButton"
        w={24}
        h={24}
        pb={4}
      ></IconButton>
      <Text
        as="span"
        fontSize="xs"
        color="white"
        position="absolute"
        bottom={5}
        left="50%"
        transform="translate(-50%, 0)"
        textTransform="uppercase"
      >
        {label}
      </Text>
    </Box>
  );

  return (
    <>
      <PageHeading justifyContent="center" alignItems="center">
        <PageHeading.Title maxW="1200px">
          cherry
          <Text as="span" fontWeight="100">
            hub portal
          </Text>
        </PageHeading.Title>
        <Text w="100%" maxW="1200px">
          Welcome {user?.displayName}
        </Text>
      </PageHeading>
      <Flex w="100%" justifyContent="center">
        <HStack
          w="100%"
          maxW="1200px"
          spacing={8}
          mb={-12}
          mt={-12}
          justifyContent="flex-end"
          pr={24}
        >
          {/* <WrapIconButton
            label="settings"
            url=""
            icon={<SettingsIcon w="100%" h={8} />}
          /> */}

          <WrapIconButton
            label="support"
            url="mailto:support@cherryhub.com.au"
            icon={<QuestionIcon w="100%" h={8} />}
          />
        </HStack>
      </Flex>
      <Flex w="100%" bgColor="white" justifyContent="center">
        <VStack
          px={4}
          py={20}
          w="100%"
          maxW="1200px"
          alignItems="flex-start"
          spacing={0}
        >
          <Heading as="h2" size="lg" color="cherryUi.600">
            Products included your subscription
          </Heading>
          <Text>Select an app to launch</Text>
          <Box w="100%" overflowX="auto">
            <HStack alignItems="flex-start" pt={8} spacing={8} pb={2}>
              {(products?.length &&
                products
                  ?.filter((product) => product != LicencedProduct.CherryPlay)
                  ?.map((product) => (
                    <DashboardMenu
                      key={product}
                      title={getProductTitle(product)}
                      buttonLabel="LAUNCH"
                      description={getProductDescription(product)}
                      icon={hubIcon}
                      url={getLaunchProductUrl(product, BusinessId)}
                    />
                  ))) || <Text fontStyle="italic">No product available.</Text>}
            </HStack>
          </Box>
        </VStack>
      </Flex>
      {findoutMoreProducts.length && (
        <Flex w="100%" bgColor="cherryGrey.150" justifyContent="center">
          <VStack
            px={4}
            py={20}
            w="100%"
            maxW="1200px"
            alignItems="flex-start"
            spacing={0}
          >
            <Heading as="h2" size="lg" color="cherryUi.600">
              Products available
            </Heading>
            <Text>
              Products available to help streamline your business - ask us how
            </Text>
            <Box w="100%" overflowX="auto">
              <HStack
                alignItems="flex-start"
                w="100%"
                pt={8}
                pb={2}
                spacing={8}
              >
                {findoutMoreProducts.map((product) => (
                  <DashboardMenu
                    key={product}
                    title={getProductTitle(product)}
                    buttonLabel="FIND OUT MORE"
                    description={getProductDescription(product)}
                    icon={hubIcon}
                    url={getFindoutMoreProductUrl(product)}
                    isExternal={true}
                  />
                )) || <Text fontStyle="italic">No product available.</Text>}
              </HStack>
            </Box>
          </VStack>
        </Flex>
      )}
    </>
  );
};

export const getLaunchProductUrl = (
  type: CherryPayApi.LicencedProduct,
  BusinessId: string
) => {
  switch (type) {
    case LicencedProduct.CherryCheck:
      return CHERRYCHECK_BASE_URL;
    case LicencedProduct.CherryPay:
      return `/businesses/${BusinessId}/members`;
    case LicencedProduct.CherryChat:
      return CHERRYCHAT_BASE_URL;
    default:
      return "";
  }
};

export const getFindoutMoreProductUrl = (
  type: CherryPayApi.LicencedProduct
) => {
  switch (type) {
    case LicencedProduct.CherryCheck:
      return "https://cherryhub.com.au/cherrycheck";
    case LicencedProduct.CherryPay:
      return "https://cherryhub.com.au/cherrypay";
    case LicencedProduct.CherryChat:
      return "https://cherryhub.com.au/cherrychat";
    default:
      return "";
  }
};

const getProductTitle = (type: CherryPayApi.LicencedProduct) => {
  switch (type) {
    case LicencedProduct.CherryChat:
      return "chat";
    case LicencedProduct.CherryPay:
      return "pay";
    case LicencedProduct.CherryCheck:
      return "check";
    default:
      return "";
  }
};

const getProductDescription = (type: CherryPayApi.LicencedProduct) => {
  switch (type) {
    case LicencedProduct.CherryCheck:
      return "The only realtime, mobile interface capturing compliance reporting anywhere.";
    case LicencedProduct.CherryPay:
      return "A mobile wallet tailored specifically for the hospitality and entertainment membership venue.";
    case LicencedProduct.CherryChat:
      return "A simple, intuitive and affordable marketing CRM platform specifically built for the hospitality and gaming industry, designed to be used by marketers of all skill levels.";
    default:
      return "";
  }
};
