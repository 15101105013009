import React from "react";
import { Alert, AlertIcon, AlertProps } from "@chakra-ui/alert";

export const ErrorMessage = ({
  children,
  ...props
}: { children: React.ReactNode } & AlertProps) => {
  return (
    <Alert status="error" {...props}>
      <AlertIcon />
      {children}
    </Alert>
  );
};
