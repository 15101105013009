export enum LicencedProduct {
  CherryPay = "CherryPay",
  CherryPlay = "CherryPlay",
  CherryChat = "CherryChat",
  CherryCheck = "CherryCheck",
}

export const LicencedProductList = () =>
  Object.entries(LicencedProduct).map(([label, value]) => {
    return value;
  });
