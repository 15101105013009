import React from "react";
import { useUserContext } from "../../context/UserContext";
import { LogoutIcon } from "../../styles/icons";
import { HStack } from "@chakra-ui/layout";
import { IconButton, Text } from "@chakra-ui/react";
import { useLayoutContext } from "../../context/LayoutContext";

export const CurrentUser = () => {
  const { user, logout } = useUserContext();
  const { isDesktop } = useLayoutContext();

  if (!user) {
    return null;
  }

  return (
    <HStack flexGrow={1}>
      <Text flex="1" color="#fff" textAlign={isDesktop ? "end" : "center"}>
        {user.displayName ?? `user ${(user as any).id}`}
      </Text>
      <IconButton
        borderRadius="full"
        icon={<LogoutIcon />}
        aria-label="Logout"
        color="#fff"
        colorScheme="cherryButton"
        onClick={logout}
        title="Logout"
      />
    </HStack>
  );
};
