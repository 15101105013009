import { StackProps, VStack } from "@chakra-ui/layout";
import React from "react";
import { useLayoutContext } from "../../context/LayoutContext";

interface PageContentProps {
  children: React.ReactNode;
}

export const PageContent = ({
  children,
  ...props
}: PageContentProps & StackProps) => {
  const { isDesktop } = useLayoutContext();

  const paddingAmounts = isDesktop
    ? { paddingLeft: "100px", paddingRight: "40px" }
    : { paddingLeft: "2", paddingRight: "2" };
  return (
    <VStack
      {...paddingAmounts}
      spacing="2"
      paddingTop="40px"
      paddingBottom="80px"
      bg="#FAFAFA"
      maxW="100vw"
      width="100%"
      {...props}
    >
      {children}
    </VStack>
  );
};
