import React from "react";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import {
  BookIcon,
  CardIcon,
  CogIcon,
  FileIcon,
  ListIcon,
  RenewalIcon,
  UserLockIcon,
  UsersIcon,
} from "../../styles/icons";
import { Link, useParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { usePermissions } from "../../hooks/usePermissions";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";

interface MenuItemProps {
  children?: React.ReactNode;
  title: string;
  target: string;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const DesktopMenuItem = ({ title, target, icon, children }: MenuItemProps) => (
  <AccordionItem bgColor="cherryUi.700">
    <h2>
      <AccordionButton>
        <Button
          as={Link}
          to={target}
          borderRadius="none"
          flex="1"
          textAlign="left"
          background="none"
          color="white"
          justifyContent="left"
          _hover={{ bgColor: "transparent" }}
          _focus={{ bgColor: "transparent" }}
        >
          <Box as="span" display="block" color="white" fontSize="sm" mr={2}>
            {icon}
          </Box>
          {title}
        </Button>
        {children && <AccordionIcon color="white" />}
      </AccordionButton>
    </h2>
    {children}
  </AccordionItem>
);

const MobileMenuItem = ({ title, target, icon, children }: MenuItemProps) => (
  <AccordionItem bgColor="cherryUi.700">
    <h2>
      <AccordionButton>
        <Button
          as={Link}
          to={target}
          borderRadius="none"
          flex="1"
          textAlign="left"
          background="none"
          color="white"
          justifyContent="left"
          _hover={{ bgColor: "transparent" }}
        >
          <Box as="span" display="block" color="white" fontSize="sm" mr={2}>
            {icon}
          </Box>
          {title}
        </Button>
        {children && <AccordionIcon color="white" />}
      </AccordionButton>
    </h2>
    {children}
  </AccordionItem>
);

const DesktopSubMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <AccordionPanel pb={4} p="0">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        {icon}
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

export const MobileSubMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <AccordionPanel pb={4} p="0">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        {icon}
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

const BusinessMenu = ({
  isDesktop,
  businessId,
  MenuItem,
}: {
  isDesktop: boolean;
  businessId: string;
  MenuItem: React.ComponentType<MenuItemProps>;
}) => {
  const [
    hasReadDetails,
    hasUserManagement,
    canSearchMembers,
    hasFinancialReportAccess,
    hasMembershipReportAccess,
    canInviteToCard,
  ] = usePermissions(
    "Member.ReadDetails",
    "User.Management",
    "Member.Search",
    "Reporting.Financial",
    "Reporting.Membership",
    "Member.InviteToCard"
  );

  const businessSummaryTitle = canSearchMembers ? "Members" : "Venues";
  const SubMenuItem = isDesktop ? DesktopSubMenuItem : MobileSubMenuItem;

  return (
    <>
      {hasReadDetails && (
        <MenuItem
          target={`/businesses/${businessId}/members`}
          title={businessSummaryTitle}
          icon={<UsersIcon boxSize="20px" />}
        />
      )}
      {hasUserManagement && (
        <MenuItem
          target={`/businesses/${businessId}/users`}
          title="Users"
          icon={<UsersIcon boxSize="20px" />}
        />
      )}

      {hasUserManagement && (
        <MenuItem
          target={`/businesses/${businessId}/roles`}
          title="Roles"
          icon={<UserLockIcon boxSize="20px" />}
        />
      )}

      {canInviteToCard && (
        <MenuItem
          target={`/businesses/${businessId}/cards`}
          title="Cards"
          icon={<CardIcon boxSize="20px" />}
        />
      )}

      {hasMembershipReportAccess && hasFinancialReportAccess && (
        <MenuItem
          target={`/businesses/${businessId}/reports`}
          title="Reports"
          icon={<FileIcon boxSize="20px" />}
        />
      )}
      {hasMembershipReportAccess && (
        <MenuItem
          target={`businesses/${businessId}/membership-requests`}
          title="Membership"
          icon={<BookIcon />}
        />
      )}
      {hasMembershipReportAccess && (
        <MenuItem
          target={`businesses/${businessId}/membership-renewal-requests`}
          title="Renewals"
          icon={<RenewalIcon />}
        />
      )}
    </>
  );
};

export const AppMenu = ({ isDesktop }: { isDesktop: boolean }) => {
  const { businessId } = useParams();
  const { userBusinessId } = useUserContext();

  const MenuItem = isDesktop ? DesktopMenuItem : MobileMenuItem;

  return (
    <Accordion allowToggle width="100%">
      {!userBusinessId && (
        <MenuItem
          target="/businesses"
          icon={<ListIcon boxSize="20px" />}
          title="Businesses"
        />
      )}

      {businessId && (
        <BusinessMenu businessId={businessId} MenuItem={MenuItem} isDesktop />
      )}

      {businessId && !userBusinessId && (
        <MenuItem
          target={`/businesses/${businessId}/configuration`}
          icon={<CogIcon boxSize="20px" />}
          title="Business Configuration"
        />
      )}
    </Accordion>
  );
};
